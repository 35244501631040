//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchFilter} from "@/api/master";
import {fetchWrongContractList} from "@/api/wrongContract";
import {Message} from "element-ui";
import func from "@/utils/decoded";
import moment from "moment/moment";
// import usersData from '../admin/UsersData'

const fields = [
	{ key: 'contract_id', label:'เลขที่สัญญา', _style:'min-width:100px' },
	{ key:'name_customer', label:'ชื่อ-สกุล', _style:'min-width:150px;'},
	{ key: 'start_date_contract', label:'วันที่เริ่มทำสัญญา', _style:'min-width:100px;' },
	{ key: 'balance', label:'ยอดเงินคงเหลือ', _style:'min-width:100px; text-align: right;' },
//   { key: 'status', label:'สถานะ', _style:'min-width:100px;' },
  { key: 'process_day', label:'เวลาดำเนินการ(วัน)', _style:'min-width:50px; text-align: center;' },
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
  name: 'WrongContract',
  data() {
    return {
      options: [],
      show: true,
			isCollapsed: true,
			items: [],
			fields,
      filterParams: {
				stepId: 12
			},
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0
			},
			details: [],
			collapseDuration: 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },

  async mounted() {
		this.$store.state.loading = true
		try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
			await new Promise((resolve, reject) => {
				fetchFilter(this.filterParams).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data

						console.log('filter : ', this.options);
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await this.getWrongContractList()
		} finally {
			this.$store.state.loading = false
		}

		const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
	},

  methods: {
    async getWrongContractList() {
			console.log('searchParams : ', this.searchParams);
			await fetchWrongContractList(this.searchParams).then(res => {
				this.items = []
				const check = res.header;
				const data = res.body;
				if (check.error == 'N') {
					// for (let item of data) {
					// 	if (item.cur_status_id == 2 && item.cur_step == 4) {
					// 		item.cur_status_id = 'รอโอนเงินสินเชื่อ'
					// 	} else if (item.cur_status_id == 0 && item.cur_step == 5) {
					// 		item.cur_status_id = 'รอโอนเงินสินเชื่อ[ข้ามขั้นตอน]'
					// 	} else if (item.cur_status_id == 5 && item.cur_step == 5) {
					// 		item.cur_status_id = 'ขอเบิกก่อน'
					// 	} else if (item.cur_status_id == 2 && item.cur_step == 5) {
					// 		item.cur_status_id = 'โอนเงินสินเชื่อ[เรียบร้อย]'
					// 	}
					// }
					this.items = data;
					console.log('items : ', this.items);
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
    getBadge (status) {
			switch (status) {
				case 'โอนเงินสินเชื่อ[เรียบร้อย]': return 'success'
				// case 'Inactive': return 'secondary'
				case 'ตรวจสอบเอกสาร[เรียบร้อย]': return 'warning'
				// case 'รับเล่มทะเบียน[ไม่เรียบร้อย]': return 'danger'
				default: 'primary'
			}
		},
    rowClickDetail(item, index){
      console.log(item.contractNumber);
      this.$router.push({path: `/step5/WrongContractDetail/${item.contract_id}`})
    },
    pageChange (val) {
      console.log(val);
      this.$router.push({ query: { page: val }})
    }
  }
}
